import React from 'react';
import Img from 'gatsby-image';
import cn from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';

import { Grid } from 'semantic-ui-react';
import { Link } from 'gatsby';

import * as Styles from 'src/styles/workouts-page.module.scss';

import { Breakpoints, Directions, useBreakpoints } from 'src/utils/breakpoint-utils';
import { IWorkoutCategoryState } from '@pvolve/sdk/src/redux/selectors';
import Colors from 'src/utils/colors';

interface WorkoutCategoryData {
    workoutCategories: IWorkoutCategoryState[];
    title?: string;
}

const Category = (category: IWorkoutCategoryState, index: number) => {
    const { placeholderImage } = useStaticQuery(
        graphql`
            query {
                placeholderImage: file(
                    relativePath: { eq: "placeholders/placeholder-square.png" }
                ) {
                    childImageSharp {
                        fluid(quality: 80) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `
    );

    const fontStyles = {
        backgroundColor: Colors.gray70,
        color: Colors.white,
    };

    const activity = category.slug.includes('meditation') ? 'sessions' : 'workouts';

    return (
        <Grid.Column
            as={Link}
            to={`/workouts/categories/${category.slug}`}
            className={Styles.workoutCategory}
            key={`workout-category-${index}`}
        >
            <div className={Styles.workoutCategoryImageContainer}>
                <Img
                    className="verticalAlign--middle"
                    fluid={
                        category?.image?.fluid
                            ? category.image.fluid
                            : placeholderImage.childImageSharp.fluid
                    }
                />
                <div className={Styles.workoutCategoryImageText}>
                    <p
                        style={{ color: fontStyles.color }}
                        className={cn(Styles.headerBg, 'bold', 'upper')}
                    >
                        <span className={Styles.spanBackground} style={fontStyles} />
                        {category.name}
                    </p>
                    {category.workouts && (
                        <span style={fontStyles}>
                            {category.workouts.length} {activity}
                        </span>
                    )}
                </div>
            </div>
        </Grid.Column>
    );
};

const WorkoutCategories = ({ workoutCategories, title }: WorkoutCategoryData) => {
    const isTabletLandscapeOrUp = useBreakpoints(Breakpoints.lg, Directions.up);
    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down);

    return (
        <Grid
            columns={isTabletLandscapeOrUp ? 3 : isMobile ? 1 : 2}
            className={Styles.workoutsCategoryContainer}
        >
            {title && <p className={`h3 upper bold margin-bottom--0`}>{title}</p>}
            <Grid.Row textAlign="center">{workoutCategories.map(Category)}</Grid.Row>
        </Grid>
    );
};

export default WorkoutCategories;
