import React from 'react';
import { IWorkout } from '@pvolve/sdk/src/models/contentful_types';
import { SearchResult } from '@pvolve/sdk/src/app/modules/content/actions';
import { WorkoutCard, CardGridWrapper } from 'src/components/shared';
import { sortWorkoutsByDate } from '@pvolve/sdk/src/app/utils';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import * as Styles from 'src/styles/workouts-search-results.module.scss';
import classNames from 'classnames';
interface SearchResultsProps {
    workoutResults: SearchResult<IWorkout>[];
    title?: string;
}

const SearchResults = ({ workoutResults, title }: SearchResultsProps) => {
    const isTabletPortraitOrBelow = useBreakpoints(Breakpoints.lg, Directions.down);

    const Result = (workout, index) => (
        <WorkoutCard workout={workout.item} key={`workout-${index}`} />
    );

    const sorted = sortWorkoutsByDate(workoutResults, 'item.fields.date');

    return (
        <div className={Styles.workoutsSearchResultsContainer}>
            {title && (
                <div
                    className={classNames(
                        isTabletPortraitOrBelow
                            ? 'display-column alignItems--flex-start'
                            : 'justifyContent-space-between'
                    )}
                >
                    <p className={`h3 upper bold margin-bottom--0`}>{title}</p>
                    <p>{`${workoutResults?.length} workouts`}</p>
                </div>
            )}
            <CardGridWrapper>{sorted.map(Result)}</CardGridWrapper>
        </div>
    );
};

export default SearchResults;
