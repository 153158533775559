// extracted by mini-css-extract-plugin
export const mini = "workouts-page-module--mini--2Z296";
export const tiny = "workouts-page-module--tiny--V16Tz";
export const small = "workouts-page-module--small--24Cro";
export const medium = "workouts-page-module--medium--3Gqx3";
export const p3 = "workouts-page-module--p3--C2IB3";
export const large = "workouts-page-module--large--1TamV";
export const p2 = "workouts-page-module--p2--Au49D";
export const l1 = "workouts-page-module--l1--1R-Qi";
export const label = "workouts-page-module--label--2Zlzi";
export const big = "workouts-page-module--big--3PJe6";
export const p1 = "workouts-page-module--p1--3MJv1";
export const huge = "workouts-page-module--huge--2PEan";
export const p0 = "workouts-page-module--p0--1q9V6";
export const huge36 = "workouts-page-module--huge36---Hv57";
export const huge52 = "workouts-page-module--huge52--2BX3e";
export const massive = "workouts-page-module--massive--1OJ43";
export const h1 = "workouts-page-module--h1--T--jt";
export const h2 = "workouts-page-module--h2--25V9w";
export const h3 = "workouts-page-module--h3--2QmeP";
export const h4 = "workouts-page-module--h4--2KY2P";
export const h5 = "workouts-page-module--h5--6qjFf";
export const workoutsContainer = "workouts-page-module--workouts-container--2d0vU";
export const workoutCategory = "workouts-page-module--workout-category--14C-1";
export const workoutCategoryImageContainer = "workouts-page-module--workout-category-image-container--2GV4d";
export const workoutCategoryImageText = "workouts-page-module--workout-category-image-text--3M7J3";
export const h6 = "workouts-page-module--h6--3f1yj";
export const accent = "workouts-page-module--accent--22SOX";
export const accenth5 = "workouts-page-module--accenth5--tlv25";
export const accenth6 = "workouts-page-module--accenth6--2VsJZ";
export const upper = "workouts-page-module--upper--2Lcss";
export const lower = "workouts-page-module--lower--22s-I";
export const underline = "workouts-page-module--underline--3q1PX";
export const bold = "workouts-page-module--bold--szADQ";
export const semibold = "workouts-page-module--semibold--CaClj";
export const normal = "workouts-page-module--normal--1cWe4";
export const stacked = "workouts-page-module--stacked--2GKWc";
export const link = "workouts-page-module--link--1AJ5k";
export const textLinks = "workouts-page-module--text-links--3sY_R";
export const headerBg = "workouts-page-module--header-bg--1P2ZX";
export const spanBackground = "workouts-page-module--span-background--3bhC7";
export const workoutHeader = "workouts-page-module--workout-header--1CmJX";
export const workoutsCtaButton = "workouts-page-module--workouts-cta-button--1IpPx";
export const workoutsCategoryContainer = "workouts-page-module--workouts-category-container--WauMn";
export const buttonWrapper = "workouts-page-module--button-wrapper--2_Zqz";
export const buttonWrapperExpanded = "workouts-page-module--button-wrapper-expanded--20gt9";